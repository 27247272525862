import React from 'react'

import NavBar from './NavBar'

import './css/FourOFour.css'

const FourOFour= () => {

    return(
        <section>
        <NavBar/>
        <div class='fourOFour'>
            <h1>404</h1>
            <h2>not found</h2>
        </div>
        </section>
    )
}

export default FourOFour