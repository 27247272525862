import React, {useEffect, useState, useRef} from "react";
import TokenProvider from "../services/tokenProvider";
import ReactTooltip from 'react-tooltip';
import NewReply from "../components/NewReply";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import Linkify from "react-linkify";
import { serverUrl } from "../App";

import './css/Post.css'
import Confirm from './Confirm'

const translateTimeAgo = (date) => {
  var res = new Date(date);
  return res.toLocaleDateString();
}

const Post = (props) => {  
  const tokenProvider = TokenProvider.getTokenProvider();
  const [isAuth] = tokenProvider.useAuth();
  const [replys, setReplys] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [topics, setTopics] = useState(props.topics);
  const [topicsText, setTopicsText] = useState("");
  const topicsDropdown = useRef(null);
  const topicsInput = useRef(null);

  useEffect(() => {
    setReplys(props.responses);
  }, [props.responses]);

  useEffect(() => {
    if(topicsInput.current) {
      const left = `${Math.min(window.innerWidth*.5, topicsInput.current.offsetLeft)}px`;
      topicsDropdown.current.style.left = left;
    }
  });

  const likePost = async() => {
    if(!isAuth){
      props.giveFeedback({message: 'Vous n\'êtes pas connecté ', type:'error', enabled:true})
      return null;
    }

    const posts = [...props.likedPosts];
    if(!props.likedPosts.includes(props._id)){
      const res = props.posts;
      res.find(post => post._id === props._id).likes++;
      props.setPosts(res);
      posts.push(props._id);
    }
    else{
      const res = props.posts;
      res.find(post => post._id === props._id).likes--;
      props.setPosts(res);
      posts.splice(posts.indexOf(props._id), 1);
    }
    props.setLikedPosts(posts);

    const res = await tokenProvider.authFetch(props.apiUrl+'/posts/like/'+props._id, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(r=>r.json());
    if(res){
      props.setLikedPosts(res.likedPosts);
    }
  }

  const copyLink = async(e) => {
    await navigator.clipboard.writeText(window.location.origin.toString()+'/post/'+props._id);
    props.giveFeedback({message: 'Lien copié dans le presse-papier', type:'success', enabled:true})
  }

  const getUrls = (text) => {
    const expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w]*)?(\?[^\s]*)?/gi;
    var regex = new RegExp(expression);
    var match = ''; var splitText = []; var startIndex = 0;
    while ((match = regex.exec(text)) != null) {
            
      splitText.push({text: text.substr(startIndex, (match.index - startIndex)), type: 'text'});
                  
      var cleanedLink = text.substr(match.index, (match[0].length));
      cleanedLink = cleanedLink.replace(/^https?:\/\//,'');
      splitText.push({text: cleanedLink, type: 'link'});
                    
      startIndex = match.index + (match[0].length);               
    }
    if (startIndex < text.length)
      splitText.push({text: text.substr(startIndex), type: 'text'});
    return splitText;
  }

  const deletePost= async() => {
    const buffer = props.posts;
    buffer.splice(buffer.indexOf(buffer.find(post=>post._id===props._id)), 1);
    props.setPosts([...buffer]);
    await tokenProvider.authFetch(props.apiUrl+'/posts/delete/'+props._id, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  }

  const toggleEditMode = () => {
    setEditing(!editing);
  }

  const updateTopics = async() => {
    toggleEditMode();

    // update local state :
    const res = props.posts;
    res.find(post => post._id === props._id).topics = [...topics];
    props.setPosts(res);

    //  send to server 
    await tokenProvider.authFetch(props.apiUrl+'/posts/update/'+props._id, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        topics: topics
      })
    }).then(r=> {
      if(!r.ok) {
        window.location.reload();
      }
    });

  };

  const handleRemoveTopic = topic => {
    var res = topics;
    const index = res.indexOf(topic);
    if (index !== -1)
      res.splice(index, 1);
    setTopics([...res]);
  }

  const handleAddTopic = topic => {
    var res = topics;
    res.push(topic);
    setTopics([...res]);
    setTopicsText("");
  }

  var responses = replys.sort((a, b)=>Date.parse(b.date) - Date.parse(a.date));
  responses = props.developResponses ? responses : responses.slice(0, 2);

  return (<>
    {confirm && <Confirm onAccept={deletePost} onCancel={()=>setConfirm(false)} message={'supprimer cette publication de '+props.author ? props.user : props.user.username}/>}
    <div class={`post ${tokenProvider.getRole()==='admin' && 'admin'}`}>
      <span className='post__delete-btn' onClick={()=>setConfirm(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
      </span>
      <div class='card'>
          <div class='card-header d-flex align-items-center'>
            <ReactTooltip />
            <div class='post__profile-image mr-3'>
              {
                props.author ?
                  <img data-tip="Ce post est copié d'une autre plateforme !" alt='' width="2em" src={`${window.location.origin}/icons/sunflower.svg`}/>
                :((props.user && props.user.image && props.user.image !== 'default') ? 
                <img alt='' src={`${serverUrl}/${props.user.image.path}`}></img> : 
                <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                    <path d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                </svg>)
              }
            </div>
            {
              props.author ?
              <a class='name' href={props.baseUrl !== "" ? props.baseUrl : '/account/'+props.author.username}>
                {props.user}
              </a> :
              <a class='name' href={'/account/'+props.user.username}>
                {props.user.username}
              </a>
            }
            <div class='badges'>
              {
                !editing ?
                props.topics.map(( topic, index) => (
                  <a href={`/topics/${topic.name.split(' ').join('-').toLowerCase()}`} class='badge badge-pill badge-primary mx-1' key={index}>
                    {topic.name}
                  </a>
                )) :
                <section class='newPost__topics post__newTopics'>
                  {
                    topics.map(( topic, index) => (
                      <div class='topics-selector__pill' key={index}>
                        {topic.name}
                        <svg onClick={()=>handleRemoveTopic(topic)} width="1.4em" height="1.4em" viewBox="0 0 16 16" class="bi bi-x pill__close" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </div>
                    ))
                  }
                  <input
                    ref ={topicsInput}
                    value={topicsText}
                    placeholder={`${topics.length === 0 ? 'Thèmes ...' : ''}`}
                    class='topics-selector__input post__topics-input'
                    readOnly
                    type='text'></input>
                  <div
                    class={`topics-selector__dropdown`}
                    ref ={topicsDropdown}>
                    {
                      props.allTopics.map((topic, index) => {
                        if(topic.name.toLowerCase().startsWith(topicsText.toLowerCase()) && !topics.includes(topic))
                          return (
                          <div key={index} onMouseDown={()=>handleAddTopic(topic)}>
                            {topic.name}
                          </div>
                        )
                        else return '';
                      })
                    }
                  </div>
                </section>
              }
            </div>
          </div>
          <div class='body'>
            <div class='content pb-3 pl-2'>
              <Linkify>{props.body}</Linkify>
              <div className='post__pictures'>
              {
                props.pictures && props.pictures.map(pic=>
                  <a href={`${serverUrl}/${pic.path}`}><img alt='' src={`${serverUrl}/${pic.path}`}></img></a>
                )
              }
              </div>
              {
                getUrls(props.body).map((url)=>(
                  url.type==='link' && ReactPlayer.canPlay(url.text) && <div className='content__embed-wrapper'><ReactPlayer url={url.text} controls={true}/></div>
                ))
              }
              {
                getUrls(props.body).map((url)=>(
                  url.text.startsWith('odysee.com/') && <div className='content__embed-wrapper'><iframe id="lbry-iframe" title='odysee' style={{ "width": "80vw", "height": "360px","max-width": "640px"}} src={"https://odysee.com/$/embed/"+url.text.split('/')[url.text.split('/').length - 1]} allowfullscreen></iframe></div>
                ))
              }
            </div>
            <span class='text-muted pl-2'>{translateTimeAgo(Date.parse(props.date))}</span>
          </div>
          <div class='card-footer p-1 d-flex'>
            <div class='btn ml-1' onClick={likePost}>
              {props.likedPosts.includes(props._id) ? 
                <svg class='post__like-button' fill='#007bff' width="1.3em" height="1.3em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                </svg>
                :
                <svg class='post__like-button' width="1.3em" height="1.3em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z"/>
                </svg>
              }
              <div class='d-inline ml-1'>{props.likes}</div>
            </div>

            <Link class='btn ml-1' to={'/post/'+props._id}>
              <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" class="bi bi-chat-left" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              </svg>
              <div class='d-inline ml-2'>{props.responses.length}</div>
            </Link>

            <div class='btn ml-1' onClick={copyLink}>
              <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" class="bi bi-share" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11.724 3.947l-7 3.5-.448-.894 7-3.5.448.894zm-.448 9l-7-3.5.448-.894 7 3.5-.448.894z"/>
                <path fill-rule="evenodd" d="M13.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-11-6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
              </svg>
            </div>
            
            {
              tokenProvider.getRole()==='admin' &&
              <div className="d-flex flex-grow-1 justify-content-end">
                {
                  editing ? 
                    <div class='btn ml-1' onClick={updateTopics}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                      </svg>
                    </div>
                  : 
                    <div class='btn ml-1' onClick={toggleEditMode}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>
                    </div>
                }
              </div>
              }
          </div>
      </div>
        {isAuth && <NewReply {...props} setReplys={setReplys} />}
        {responses.map((response, index) => (
          <div class='response mt-1 ml-4' key={index}>
            <div class='card m-0 p-1'>
              <div class='card-body d-flex flex-wrap p-2 align-items-center'>
                <div class='header d-flex align-items-center'>
                  <div class='response__profile-pic'>
                    {
                      response.user.image && response.user.image !== 'default' ? 
                      <img alt='' src={`${serverUrl}/${response.user.image.path}`}></img> : 
                      <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                          <path d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                          <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                      </svg>
                    }
                  </div>
                  <a class='name' href={'/account/'+response.user.username}>
                    {response.user.username}
                  </a>
                </div>
                <div class='body flex-grow-1 d-flex flex-wrap flex-row justify-content-between align-items-center pb-1'>
                  <span class='content'><Linkify>{response.body}</Linkify></span>
                  <span class='text-muted flex-shrink-0'>{translateTimeAgo(Date.parse(response.date))}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div></>
  )
}

export default Post;