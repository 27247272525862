import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import './css/Forgot.css';

const Forgot = (props) => {

  const [userOrEmail, setUserOrEmail] = useState('');
  const [isSent, send] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var params = {};
    if(userOrEmail.includes('@'))
      params.email = userOrEmail;
    else
      params.username = userOrEmail;

    fetch(props.apiUrl+'/auth/forgot', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
    send(true);
  }
  
  return <>
  <div className='forgot'>
    <form className='forgot__form'>
      <div class='forgot__icon'>
          <Link to='/'><img alt='sunflower' width='120' src='/icons/sunflower.svg'/></Link>
      </div>
      {
        isSent ? <>
          <h3 class='mb-4'>Toujours là ?</h3>
          <p className='forgot__recovery-sent'>Un email de récupération vous a été envoyé !</p>
          </> : <>
          <h3 class='mb-4'>Mot de passe oublié ?</h3>
          <label for='email' className=''>Nom d'utilisateur ou email</label>
          <input type='text' value={userOrEmail} onChange={e=>setUserOrEmail(e.target.value)} class="mb-2 form-control" id="email"></input>
          <button type="submit" disabled={userOrEmail === ''} onClick={handleSubmit} class={`btn btn-success`}>M'envoyer un lien de récupération</button>
        </>
      }
    </form>
  </div>
</>
}

export default Forgot;