import { apiUrl } from "../App";

const getUsernameValidity=
async function getUsernameValidity({ username }){
  if(username.length < 2)
    return 'Le nom d\'utilisateur est trop court (minimum 2 caractères).';

  else if(username.length > 39)
    return 'Le nom d\'utilisateur est trop long (maximum 39 caractères).';

  else if(!Array.from(username).some(char=>char.match(/[A-Za-z]/)) || !/^([A-Za-z0-9-_])*$/.test(username))
    return 'Le nom d\'utilisateur ne doit contenir que des chiffres, lettres, tirets ou underscore et au moins une lettre.';

  else {
    const isUsed = await fetch(`${apiUrl}/auth/isUsernameUsed`,{
      method: 'POST',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
          username
      })
    })
    .then(res => !res.ok)

    if(isUsed)
      return 'Le nom d\'utilisateur \''+username+'\' n\'est pas disponible.';
    else
      return '';
  }
}

const getEmailValidity=
async function getEmailValidity({ email }){
  // eslint-disable-next-line
  if(!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(email))
    return 'L\'adresse email est invalide ou déjà utilisée.'
  
  const isUsed = await fetch(`${apiUrl}/auth/isEmailUsed`,{
    method: 'POST',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        email
    })
  })
  .then(res => !res.ok)

  if(isUsed)
    return 'L\'adresse email est invalide ou déjà utilisée.'
  else
    return '';
}

const getPasswordValidity=
async function getPasswordValidity({ password }){
  if(password.length < 8)
    return 'Le mot de passe est trop court (minimum 8 caractères).';

  else if(password.length > 72)
    return 'Le mot de passe est trop long (maximum 72 caractères).';

  else if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,72}$/.test(password))
    return 'Le mot de passe requiert au moins une majuscule, une minuscule et un nombre.';

  else
    return '';

}

export {getEmailValidity, getUsernameValidity, getPasswordValidity};