import React from 'react'

import './css/Loading.css'

const Loading = () => (
  <div class='spinner'>
    <img class='spinner__icon' alt='Loading...' width='180' src={`${window.location.origin}/icons/sunflower.svg`}/>
  </div>
)

export default Loading;