import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import Post from '../components/Post';
import Loading from '../components/Loading';
import DisplayMessage from '../components/DisplayMessage';
import NavBar from './NavBar';

import './css/SinglePost.css';

const SinglePost = (props) => {

  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);
  const [message, setMessage] = useState({message: '', type:'', enabled:false});
  const history = useHistory();
  const {id} = useParams();

  useEffect(() => { 
    const getData = async() => {
      setLoading(true);
      const data = await fetch(props.apiUrl+'/posts/'+id).then(r => {
          if(!r.ok){
              history.push('404');
              return null;
          }
          else return r.json();
      });
      setPost(data.post);
      
      setLoading(false);
    }
    getData(); 
  }, [history, id])

  return <>
    {
        <DisplayMessage type={message.type} enabled={message.enabled} onClose={()=>setMessage({enabled: false})}>
          {message.message}
        </DisplayMessage>
    }
    <NavBar/>
    <div className='single-post'>
      {
        loading ? <Loading/> :
        <Post {...post}
          apiUrl={props.apiUrl}
          key={post.id}
          isLiked={props.likedPosts.includes(post._id)}
          setLikedPosts={props.setLikedPosts}
          likedPosts={props.likedPosts}
          posts={[post]} setPosts={(posts)=>setPost(posts[0])}
          giveFeedback={msg=>setMessage(msg)} 
          allTopics={props.allTopics}
          developResponses />
      }
    </div>
  </>
}

export default SinglePost;