import React from 'react';

import './css/Footer.css';

const Footer = () => <>
  <footer className='footer'>
    <div className='footer__logo-container'>
      <img class='footer__logo' width='100' src={`${window.location.origin}/icons/sunflower.svg`}/>
    </div>
    <div className='footer__contact'>
      <div className='footer__column-title footer__column-item'>Contacts</div>
      <a href='mailto:support@electroculturecommunity.com' className='footer__column-item'>support@electroculturecommunity.com</a>
      <a href='tel:(+33)6 88 08 68 94' className='footer__column-item'>(+33)6 88 08 68 94</a>
    </div>
    <div className='footer__websites'>
      <div className='footer__column-title footer__column-item'>Références</div>
    </div>
    <div className='footer__stores'>
      <div className='footer__column-title footer__column-item'>Matériel</div>
      <a href='https://www.electroculturevandoorne.com/' className='footer__column-item'>Boutique</a>
    </div>
  </footer>
</>

export default Footer;