import React, {useState, useEffect, useRef} from "react";
import { useParams, useHistory } from "react-router-dom";

import Loading from "../components/Loading";
import NavBar from "./NavBar";
import Post from "../components/Post";
import DisplayMessage from "../components/DisplayMessage";
import TokenProvider from "../services/tokenProvider";
import Footer from "../components/Footer";
import { serverUrl } from "../App";

import './css/Profile.css';
var autosize = require('autosize')

const Profile = (props) => {
  const {login} = useParams();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [newPic, setNewPic] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();
  const tokenProvider = TokenProvider.getTokenProvider();
  const [isAuth] = tokenProvider.useAuth();

  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [message, setMessage] = useState({message: '', type:'', enabled:false});
  const textarea = useRef(null);

  const getPosts = async() => {
    const res = await fetch(props.apiUrl+'/posts/latests?user='+login+'&page='+page).then(r => {
      if(!r.ok)
        history.push('/404');
      else return r.json();
    });
    if(res.posts.length === 0){
      setPage(-1);
      if(posts.length !== 0)
        setMessage({message: 'Vous avez tout vu', type:'error', enabled:true});
    } 
    else{
      setPosts([...posts,...res.posts]);
      setPage(page + 1);
    }
  }

  const promote = async(role) => {
    const old = userData.role;
    var updated = userData;
    userData.role = role;
    setUserData({...updated});
    const res = await tokenProvider.authFetch(props.apiUrl+'/auth/promote/'+login,{
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({role: "editor"})
    }).then(r => r);
    console.log(res)
    if(!res.ok) {
      userData.role = old;
      setUserData({...updated});
    }
  }

  /*Async calls*/
  useEffect(() => {
    const getUserData= async ()=>{
      setLoading(true);
      const user = isAuth && tokenProvider.getRole() === 'admin' ? 
        await tokenProvider.authFetch(props.apiUrl+'/auth/'+login+'/admin').then(r => r.ok ? r.json() : null) :
        await fetch(props.apiUrl+'/auth/'+login).then(r => r.ok ? r.json() : null);
      
      console.log(user)
      if(user===null)
        history.push('/404');
      setUserData(user);
      await getPosts();
      setLoading(false);
    }
    getUserData();
    autosize(textarea.current);

  }, [login, history])

  const handleBioChange = (e) => {
    const res = userData;
    res.bio = e.target.value;
    setUserData({...res});
  }

  const handlePictureChange = (e) => {
    const res = userData;
    const newImg = e.target.files[0];
    res.image = {blob: newImg, objUrl: URL.createObjectURL(newImg)};
    setUserData({...res});
    setNewPic(res.image);
  }

  const handleSubmitProfile= async() => {
    if(!isEditing)
      setIsEditing(true);
    else {
      setLoading(true);
      setIsEditing(false);
      // Bio :
      await tokenProvider.authFetch(props.apiUrl+'/auth/bio/update', {
        method: 'PUT',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          bio: userData.bio.trim()
        })
      })
      
      // Picture :
      if(newPic) {
        const formData = new FormData();
        formData.append('picture', newPic.blob);
        await tokenProvider.authFetch(props.apiUrl+'/auth/pic/update', {
          method: 'PUT',
          body: formData
        })
      }
      setLoading(false);
    }
  }
  
  return<>
    {
      <DisplayMessage type={message.type} enabled={message.enabled} onClose={()=>setMessage({enabled: false})}>
          {message.message}
      </DisplayMessage>
    }
    <NavBar/>
    <div class={`profile ${loading ? 'loading' : ''}`}>
      {
        loading ? <Loading/> : <>
        <article className='profile__pres'>
        <section className='pres__image-wrapper'>
          {
            userData.image && userData.image !=='default' ? 
            <img alt='' src={`${userData.image.objUrl ? userData.image.objUrl : serverUrl+"/"+userData.image.path}`} className='pres__image'></img> :
            <svg viewBox="0 0 16 16" className='pres__image' fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
              <path d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
            </svg>
          }
          <label className={`pres__add-image ${isEditing && isAuth && login === tokenProvider.getUsername() && 'visible'}`} htmlFor='pp'>
            <svg className='pres__add-image-icon' fill="currentColor" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </label>
          <input
            className='pres__image-form'
            id='pp'
            type="file"
            accept=".jpeg,.jpg,.png,.gif"
            onChange={handlePictureChange}
          ></input>
        </section>
        <section className='pres__content'>
          <h1>{userData.username}</h1>
          {
            userData.email && <div className='pres__email'>({userData.email})</div>
          }
          {
            tokenProvider.getRole() === "admin" && userData.role !== "user" &&
            <div className={`badge badge-pill badge-${userData.role === "admin"? "warning": "primary"} mx-2`}>
              {userData.role === "admin" ? "Administrateur" : "Rédacteur"}
            </div>
          }
          {
            tokenProvider.getRole() === "admin" && userData.role === "user" && 
            <div className="btn btn-warning mx-3" onClick={promote}>Rendre rédacteur</div>
          }
        </section>
      </article>
      <div className='profile__divider'></div>
      {
        isAuth && login === tokenProvider.getUsername() &&
        <div className={`profile__edit-button ${isEditing && 'editing'}`} onClick={handleSubmitProfile}>
          {isEditing ? <>
              <svg className='profile__edit-icon' width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
                <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
              </svg>
              Enregistrer
            </> :
            <>
              <svg className='profile__edit-icon' width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
              </svg>
              Éditer le profile
            </>
          }
          
        </div>
      }
      {
        isEditing ?
          <textarea
            className='profile__description editing'
            placeholder='Présentez vous en quelques mots..'
            value={userData.bio}
            rows={1}
            ref={textarea}
            onChange={handleBioChange}
            >
          </textarea> :
        <>
          {
            userData.bio !== '' && 
            <article className='profile__description'>
              {userData.bio}
            </article>
          }
        </>
      }
      <div className='profile__posts'>
        <div className='profile__posts-title'>
          <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-arrow-down-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path fill-rule="evenodd" d="M4.646 7.646a.5.5 0 0 1 .708 0L8 10.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z"/>
            <path fill-rule="evenodd" d="M8 4.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          <h1>Dernières publications</h1>
        </div>
        <div class='profile__posts-list'>
        {
          posts.map((post) => (
            <Post 
              {...post}
              apiUrl={props.apiUrl}
              key={post._id}
              setLikedPosts={props.setLikedPosts}
              likedPosts={props.likedPosts}
              posts={posts}
              setPosts={setPosts}
              giveFeedback={msg=>setMessage(msg)}
              allTopics={props.allTopics}
            />
          ))
        }
        {page !== -1 && <div className='btn btn-primary align-self-center' onClick={getPosts}>Voir plus</div>}
        </div>
      </div>
      </>
      }
    </div>
    <Footer/></>
}

export default Profile