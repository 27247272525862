import React, {useState} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom'
import { getPasswordValidity } from '../services/signUpValidation';
import Loading from '../components/Loading';

import './css/Reset.css';

const Reset = (props) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValidity, setPasswordValidity] = useState('');
  const [isSent, send] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const { token } = useParams();
  if(!token)
    history.push('/forgot');


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    getPasswordValidity({password: e.target.value}).then(validity=>{
      setPasswordValidity(validity);
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await fetch(props.apiUrl+'/auth/reset', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({password, token})
    }).then(r=>r.status);
    if(res === 204)
      setSuccess(true);
    send(true);
    setLoading(false);
  }

  return<>
  <div className='reset'>
    {
      loading ? <Loading/> :
        <form className='reset__form'>
        <div class='reset__icon'>
            <Link to='/'><img alt='sunflower' width='120' src='/icons/sunflower.svg'/></Link>
        </div>
        {
          isSent ? <>
          {
            success ? <div className='reset__success'>
              <h3 class='mb-4'>Succès !</h3>
              <p>Votre mot de passe a bien été modifié</p>
              <Link to="/login" className='btn btn-success'>Me connecter</Link>
            </div> :
            <div className='reset__error'>
              <h3 class='mb-4'>Erreur</h3>
              <p>Désolé, votre lien de récupération est expiré !</p>
              <Link to="/forgot" className='btn btn-warning'>Réessayer</Link>
            </div>
          }
          </> :
          <>
            <h3 class='mb-4'>Changer de mot de passe</h3>
            <label for='password' className=''>Mot de passe</label>
            <input type='password' value={password} onChange={handlePasswordChange} class="mb-2 form-control" id="password"></input>
            {
              passwordValidity !== '' && <div className='reset__feedback'>{passwordValidity}</div>
            }
            <button type="submit" disabled={password === '' } onClick={handleSubmit} class={`btn btn-success`}>Valider</button>
          </>
        }
        
      </form>
    }
  </div>
</>
}
export default Reset;