import React, {useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';

import './css/Logout.css';

import Loading from '../components/Loading';
import TokenProvider from '../services/tokenProvider';

const Logout = () => {

  const tokenProvider = TokenProvider.getTokenProvider();
  const [isAuth] = tokenProvider.useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleLogout = async() => {
    setIsLoading(true);
    await tokenProvider.logout();
    setIsLoading(false);
    history.push('/');
  }

  if(!isAuth)
    return <Redirect to='/'/>

  return(
    <div class='logout'>
      {
        isLoading ? <Loading/> :
        <form>
            <div class='icon'>
                <img alt='sunflower' width='120' src={window.location.origin.toString()+'/icons/sunflower.svg'}/>
            </div>
            <h3 class='logout__title'>Êtes-vous sûr ?</h3>
            <p class='logout__msg'>Vous pourrez toujours vous reconnecter à n'importe quel moment. À bientôt !</p>
            <div class='buttons'>
              <div class='button-cancel' onClick={history.goBack}>Annuler</div>
              <div class='button-logout' onClick={handleLogout}>Me déconnecter</div>
            </div> 
        </form>
      }
    </div>
  )
}

export default Logout;