import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import './css/SignUp.css'
import Loading from '../components/Loading'

import TokenProvider from '../services/tokenProvider';
const validation = require('../services/signUpValidation');

class SignUp extends React.Component {

    constructor(props){
        super(props)

        this.tokenProvider = TokenProvider.getTokenProvider();

        this.state = {
            isLoggedIn: this.tokenProvider.isLoggedIn(),
            username: '',
            email: '',
            password: '',
            usernameValid: true,
            usernameFeedback: '',
            emailValid: true,
            emailFeedback: '',
            passwordValid: true,
            passwordFeedback: '',
            isLoading: false,
            signupError: ''
        }

        this.handleUsernameChange = this.handleUsernameChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        const listener = (newIsLogged) => {
            this.setState({ isLoggedIn: newIsLogged });
        };
        this.tokenProvider.subscribe(listener);
    }

    componentWillUnmount(){
        this.tokenProvider.unsubscribe();
    }

    handleUsernameChange(event){
        const username = event.target.value.toString();
        this.setState({ username });

        validation.getUsernameValidity({ username })
            .then(validity => {
                this.setState({
                    usernameValid: validity === '',
                    usernameFeedback: validity
                });
            })
    }

    handleEmailChange(event){

        const email = event.target.value.toString();
        this.setState({ email });

        validation.getEmailValidity({ email })
            .then(validity => {
                this.setState({
                    emailValid: validity === '',
                    emailFeedback: validity
                });
            })
    }

    handlePasswordChange(event){
        const password = event.target.value.toString();
        this.setState({ password });

        validation.getPasswordValidity({ password })
            .then(validity => {
                this.setState({
                    passwordValid: validity === '',
                    passwordFeedback: validity
                });
            })
    }

    handleSubmit(event){
        event.preventDefault();

        const { username, email, password } = this.state;

        //On met la page en chargement :
        this.setState({ isLoading: true });

        //On lance la requête :
        fetch(this.props.apiUrl+'/auth/signup',{
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                email,
                password
            })
        })
        .then(res => {
            if(!res.ok){
                this.setState({
                    signupError: 'Une erreur est survenue.',
                    isLoading: false,
                    username: '',
                    email: '',
                    password: ''
                })
            }
            else
                return res.json();
        })
        .then(json => {
            this.setState({
                isLoading: false,
                username: '',
                email: '',
                password: ''
            });
            this.tokenProvider.setToken(json);
        })
        .catch( error => {
            console.error(error);
        })
    }

    render(){
        if(this.state.isLoggedIn)
            return <Redirect to='/' />;

        const { 
            username,
            usernameValid,
            usernameFeedback,
            email,
            emailValid,
            emailFeedback,
            password,
            passwordValid,
            passwordFeedback,
            isLoading,
            signupError
        } = this.state
        
        return(
            <div className='signUp'>
                {   
                    isLoading ? <Loading class='loading'/> :
                    <form onSubmit={this.handleSubmit}>
                        <div class='header'>
                            <div class='icon'>
                                <Link to='/'><img alt='' width='100' src='icons/sunflower.svg'/></Link>
                            </div>
                            <h3>S'inscrire</h3>
                        </div>

                        <label for='username' class='mb-3'>Nom d'utilisateur</label>
                        <input type='text' value={username} onChange={this.handleUsernameChange} class={`${usernameValid ? '' : 'is-invalid'} mb-2 form-control`} id='username'></input>
                        <div class="invalid-feedback mb-2">
                            {usernameFeedback}
                        </div>

                        <label for='email' class='mb-3'>Adresse email</label>
                        <input type='email' value={email} onChange={this.handleEmailChange} class={`${emailValid ? '' : 'is-invalid'} mb-2 form-control`} id="email"></input>
                        <div class="invalid-feedback mb-2">
                            {emailFeedback}
                        </div>

                        <label for='password' >Mot de passe</label>
                        <input type='password' value={password} onChange={this.handlePasswordChange} class={`${passwordValid ? '' : 'is-invalid'} mb-3 form-control`} id="password"></input>
                        <div class="invalid-feedback mb-3">
                            {passwordFeedback}
                        </div>

                        <button type="submit" disabled={!(usernameValid && emailValid && passwordValid)} class={`${emailValid && passwordValid ? '' : 'disabled'} btn btn-success`}>S'inscrire</button>
                        {
                        signupError ? <div class="text-center alert alert-danger alert-dismissible fade show mt-3" role="alert" >
                            {signupError}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> : ""
                        }
                        <Link to='/login' class='btn orLogin'>Ou, se connecter</Link>
                    </form>
                }
            </div>
        )
    }
    
}

export default SignUp