import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types'

import './css/Topic.css'

import Post from '../components/Post'
import Loading from '../components/Loading'
import DisplayMessage from '../components/DisplayMessage'

const Topic = (props) => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [message, setMessage] = useState({message: '', type:'', enabled:false});
    const [page, setPage] = useState(0);
    const history = useHistory();

    const getPosts = async()=>{
        if(page === 0)
            setLoading(true);

        const res = await fetch(props.apiUrl+'/posts/latests/'+'?topic='+props.name+'&page='+page).then(r => {
            if(!r.ok){
                history.push('404');
                return null;
            }
            else return r.json();
        });
        const buffer = posts;
        if(res.posts.length === 0){
            setPage(-1);
            setMessage({message: 'Vous avez tout vu', type:'error', enabled:true});
        } 
        else{
            buffer.push(...res.posts)
            setPosts(buffer);
            setPage(page + 1);
        }
        setLoading(false);
    }

    useEffect(() => { 
        getPosts(); 
    }, [history, props.name]);

    return(
        <section>
        {
            <DisplayMessage type={message.type} enabled={message.enabled} onClose={()=>setMessage({enabled: false})}>
                {message.message}
            </DisplayMessage>
        }
        <div class='topic'>
            <div class='post-form'>
                <h1 class='title text-primary d-flex flex-row align-items-center'><span class='text-secondary h6 mr-2'>sujet:</span>{props.name}</h1>
            </div>

            <div class={`posts-list ${loading ? 'loading' : ''}`}>
            {
                loading ? <Loading/> :
                posts.map((post) => (
                    <Post {...post}
                        apiUrl={props.apiUrl}
                        key={post._id}
                        isLiked={props.likedPosts.includes(post._id)}
                        setLikedPosts={props.setLikedPosts}
                        likedPosts={props.likedPosts}
                        posts={posts}
                        setPosts={setPosts}
                        giveFeedback={msg=>setMessage(msg)} 
                        allTopics={props.allTopics}
                    />
                ))
            }
                {!loading && page !== -1 && <div className='btn btn-primary align-self-center' onClick={getPosts}>Voir plus</div>}
            </div>
        </div>
        </section>
    )
}

Topic.propTypes = {
    name: PropTypes.string
}

export default Topic;