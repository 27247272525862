import {useEffect, useState} from 'react';
import { apiUrl } from "../App";

class TokenProvider{

  constructor(){
    this._token = getCookie('REACT_TOKEN_AUTH') ? JSON.parse(getCookie('REACT_TOKEN_AUTH')) : null;
    this.observers = [];
  }

  static getTokenProvider(){
    if(!this.provider)
      this.provider = new TokenProvider();
    return this.provider;
  }

  getExpirationDate(jwtToken){
      if (!jwtToken) {
          return null;
      }
  
      const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
  
      // multiply by 1000 to convert seconds into milliseconds
      return (jwt && jwt.exp && jwt.exp * 1000)  || null;
  }

  getUsername = () => {
    if(!this._token)
      return null;
    const jwt = JSON.parse(atob(this._token.accessToken.split('.')[1]));
    return (jwt && jwt.user) ? jwt.user : null; 
  }

  getRole = () => {
    if(!this._token)
      return null;
    const jwt = JSON.parse(atob(this._token.accessToken.split('.')[1]));
    return (jwt && jwt.role) ? jwt.role : null;
  }

  getImage = () => {
    if(!this._token)
      return null;
    const jwt = JSON.parse(atob(this._token.accessToken.split('.')[1]));
    return (jwt && jwt.image) ? jwt.image : null;
  }

  isExpired(exp){
    if (!exp) {
        return false;
    }

    return Date.now() > exp;
  };

  getToken = async () => {
    if (!this._token) {
        return null;
    }

    if (this.isExpired(this.getExpirationDate(this._token.accessToken))) {
        const updatedToken = await fetch(`${apiUrl}/auth/refresh-token`, {
            method: 'POST',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              username: this.getUsername(),
              refreshToken: this._token.refreshToken
            })
        }).then(r => (!r.ok) ? null : r.json() );

        this.setToken(updatedToken);
    }

    return this._token && this._token.accessToken;
  };

  logout = async() => {
    await this.authFetch(`${apiUrl}/auth/revoke-token`,{
      method: 'POST',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
        refreshToken: this._token.refreshToken
      })
    })
    this.setToken();
  }

  isLoggedIn = () => {
    return !!this._token;
  };

  subscribe = (update) => {
    this.observers.push(update);
  };

  unsubscribe = (update) => {
    this.observers = this.observers.filter(_observer => _observer !== update);
  };

  notify = () => {
    const isLogged = this.isLoggedIn();
    this.observers.forEach(observer => observer(isLogged));
  };

  setToken = (token) => {
    if (token) {
      setCookie(['REACT_TOKEN_AUTH', JSON.stringify(token)]);
    } else {
      removeCookie('REACT_TOKEN_AUTH');
    }
    this._token = token;
    this.notify();
  };

  authFetch = async (input, init) => {
    const token = await this.getToken();
    if(!token) return null;

    init = init || {};

    init.headers = {
        ...init.headers,
        Authorization: `Bearer ${token}`,
    };

    return fetch(input, init);
  };

  useAuth = () => {
    const [isLogged, setIsLogged] = useState(this.isLoggedIn());

    useEffect(() => {
        const listeners = (newIsLogged) => {
            setIsLogged(newIsLogged);
        };
        this.subscribe(listeners);
        return () => {
            this.unsubscribe(listeners);
        };
    }, []);

    return [isLogged];
  };

};


function getCookie(key) {
  var res = document.cookie
    .split(";")
    .find(row=>row.replace(" ","").startsWith(key));
  return res ? res.split("=")[1] : undefined;
}

function setCookie([key, value]) {
  removeCookie(key);
  var now = new Date();
  now.setDate(now.getDate()+7);
  document.cookie = key+"="+value+"; expires = "+now.toUTCString();
}

function removeCookie(key) {
  if(getCookie(key)) {
    document.cookie = key+"= ;";
  }
}

export default TokenProvider;