import React, { useRef, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import './css/Home.css'

import NavBar from './NavBar'
import TokenProvider from '../services/tokenProvider';
import Post from '../components/Post'
import NewPost from '../components/NewPost'
import Loading from '../components/Loading'
import DisplayMessage from '../components/DisplayMessage'
import Footer from '../components/Footer'
import TimeAgo from 'javascript-time-ago'


const Home = (props) => {
    const timeAgo = new TimeAgo('en-US');
    const tokenProvider = TokenProvider.getTokenProvider();
    const [isAuth] = tokenProvider.useAuth();

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({message: '', type:'', enabled:false});
    const [page, setPage] = useState(0);

    const translateTimeAgo = (text) => {
        var res = text.replace('a ', '1 ');
    
        if(text.includes('ago')){
            res = 'Il y a '+res.split(' ')[0]+' ';
            if(text.includes('second'))
                res+='seconde(s)'
            else if(text.includes('minute'))
                res+='minute(s)'
            else if(text.includes('hour'))
                res+='heure(s)'
            else if(text.includes('day'))
                res+='jour(s)'
            else if(text.includes('week'))
                res+='semaine(s)'
            else if(text.includes('month'))
                res+='moi(s)'
            else
                res+='an(s)'
        }
        else
            res = 'À l\'intant'
    
        return res.replace("an", "une");
    }
    
    const getPosts = async(autoRefresh = false)=>{
        var realPage = page;
        var realPosts = posts;
        if(autoRefresh === true){
            setPage(0)
            realPage = 0;
            setPosts([])
        }
        if(realPage === 0)
            setLoading(true);

        const res = await fetch(props.apiUrl+'/posts/latests?page='+realPage).then(r => {
            if(!r.ok){
                setMessage({message: 'Erreur inattendue', type:'error', enabled:true});
                return null;
            }
            else return r.json();
        });
        if(res.posts.length === 0){
            setPage(-1);
            setMessage({message: 'Vous avez tout vu', type:'error', enabled:true});
        } 
        else{
            realPosts.push(...res.posts)
            setPosts(realPosts);
            setPage(page + 1);
        }
        setLoading(false);
    }

    useEffect(() => {
        getPosts();   
    }, [])

    return(
        <>
        {
            <DisplayMessage type={message.type} enabled={message.enabled} onClose={()=>setMessage({enabled: false})}>
                {message.message}
            </DisplayMessage>
        }
        <NavBar/>
        {!isAuth && 
                <div className='about'>
                    <img class='bg' alt='background' src={`${window.location.origin}/images/banniere.jpg`}></img>
                    <div class='about__flowers'>
                        <img class='bg__stem_sunflower' alt='sunflower' src={`${window.location.origin}/images/stem_sunflower.png`} style={{width: '10%',maxWidth: '8rem', left: '120px'}}/>
                        <img class='bg__stem_sunflower' alt='sunflower' src={`${window.location.origin}/images/stem_sunflower.png`} style={{width: '15%',maxWidth: '12rem', left: '20px'}}/>
                        <img class='bg__stem_sunflower' alt='sunflower' src={`${window.location.origin}/images/stem_sunflower.png`} style={{width: '7%',maxWidth: '5rem', left: '20px'}}/>
                    </div>
                    <div class='content'>
                        <h1>Groupe de partage d'électroculture</h1>
                        <h2>Electroculture : Énergies naturelles au Service de l'Agriculture</h2>
                        <p>L'objectif est d'offrir à tous les cultivateurs, jardiniers et agriculteurs un outil de partage, de diffusion et d'apprentissage des techniques liées à l'électroculture.</p>
                        <a href='#posts' class='about__explore-btn'>Explorer</a>
                    </div>
                </div>
        }
        <div className='home'>
            <aside className='home__aside'>
                <aside className='home__aside-topics'>
                    <div class='home__aside-title'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" class="bi bi-stars" viewBox="0 0 16 16">
                            <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z"/>
                        </svg>
                        <h2 class='mx-2' >Tendances</h2>
                    </div>
                    <div className='aside__topics-list'>
                        {props.allTopics.slice(0,8).sort((a,b)=>Date.parse(b.lastUpdate) - Date.parse(a.lastUpdate)).map((topic, index) => (
                            <Link to={`topics/${topic.name.split(' ').join('-').toLowerCase()}`} key={index}>
                                <div class="d-flex w-100 flex-column px-3">
                                    <h5 class="mb-1">{topic.name}</h5>
                                    <small >{topic.lastUpdate ? translateTimeAgo(timeAgo.format(Date.parse(topic.lastUpdate))) : 'Pas de publications'}</small>
                                </div>
                            </Link>
                        ))}
                        <Link className='aside__topics-more' to="/topics">Voir plus</Link>
                    </div>
                </aside>
            </aside>

            {isAuth &&
                <div className="publish">
                    <div class='title' >
                        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-house" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                            <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                        </svg>
                        <h1>Accueil</h1>
                    </div>
                    <div className="publish-form">
                        <NewPost apiUrl={props.apiUrl} allTopics={props.allTopics} giveFeedback={msg=>setMessage(msg)} refreshPosts={getPosts} />
                    </div>
                </div>
            }
            
            <div className='popular' id='posts'>
                <div class='title' >
                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-arrow-down-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path fill-rule="evenodd" d="M4.646 7.646a.5.5 0 0 1 .708 0L8 10.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z"/>
                        <path fill-rule="evenodd" d="M8 4.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                    <h1>Dernières publications</h1>
                </div>
                {
                    loading ? <Loading/> :
                    <div class='posts-list'>
                    {
                        posts.map((post) => (
                            <Post 
                                {...post}
                                apiUrl={props.apiUrl}
                                key={post._id}
                                setLikedPosts={props.setLikedPosts}
                                likedPosts={props.likedPosts}
                                posts={posts}
                                setPosts={setPosts}
                                giveFeedback={msg=>setMessage(msg)}
                                allTopics={props.allTopics}
                            />
                        ))
                    }
                        {page !== -1 && <div className='btn btn-primary align-self-center' onClick={getPosts}>Voir plus</div>}
                    </div>
                }
                
            </div>
            
        </div>
        <Footer/>
        </>
    )
}

export default Home