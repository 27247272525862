import React, {useState} from "react";
import { Link, useLocation} from "react-router-dom";

import TokenProvider from "../services/tokenProvider";
import { serverUrl } from "../App";

import "./css/NavBar.css";

const NavBar = () => {

    const { pathname } = useLocation();
    const tokenProvider = TokenProvider.getTokenProvider();
    const [isAuth] = tokenProvider.useAuth();
    const [isProfileToggled, setProfileToggled] = useState(false);

    const toggleProfile = () => {
        setProfileToggled(!isProfileToggled);
    }

    return(
        <nav class='navbar sticky-top navbar-expand-md navbar-light bg-light'>
            <Link class='navbar-brand mb-0 h1 d-flex align-items-center' to='/' >
                <img alt='' class='mr-2' width='30' src={window.location.origin.toString()+'/icons/sunflower_bw.svg'}/>
                électroculture
            </Link>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarContent">
                <ul class="navbar-nav mr-auto">

                    <li class='nav-item  mx-sm-2 ml-2'>
                        <Link class={`nav-link ${pathname === '/topics' ? 'active' : ''}`} to='/topics'>Parcourir</Link>
                    </li>
                    
                </ul>

                {
                    isAuth ? 
                    <div class="navbar__profile">
                        <div class='navbar__profile__content' onClick={toggleProfile}>
                            {tokenProvider.getRole() === 'admin' && <div className='badge badge-pill badge-warning mr-2'>Admin</div>}
                            <div class='navbar__profile__pic'>
                            {
                                tokenProvider.getImage() && tokenProvider.getImage() !== 'default' ? 
                                <img alt='' src={`${serverUrl}/${tokenProvider.getImage().path}`}></img> : 
                                <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                                    <path d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                                </svg>
                            }
                            </div>
                            <div class="navbar__profile__name">
                                {tokenProvider.getUsername()}
                            </div>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down navbar__profile__chevron" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                        
                        <div class={`navbar__profile__dropdown ${isProfileToggled ? 'navbar__dropdown__active' : ''}`}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-up navbar__profile__caret" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.204 11L8 5.519 12.796 11H3.204zm-.753-.659l4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"/>
                            </svg>
                            <a class='navbar__profile__link' href={`/account/${tokenProvider.getUsername()}`} onClick={toggleProfile}>Mon profil</a>
                            <div class='navbar__profile__dropdown__divider'></div>
                            <Link class='navbar__profile__logout' to='/logout' onClick={() => {toggleProfile()}}>Se déconnecter</Link>
                        </div>
                    </div>
                    :
                    <ul class="navbar-nav">
                        <li class='nav-item my-1 mr-sm-2 mr-xs-2'>
                            <Link class='btn btn-primary' to='/signup'>S'inscrire</Link>
                        </li>
                        <li class='nav-item my-1'>
                            <Link class='btn btn-outline-secondary' to='/login'>Connexion</Link>
                        </li>
                    </ul>
                }
                

            </div>

        </nav>)
}

export default NavBar