import React from 'react'
import { serverUrl } from '../App';

import './css/NewPost.css'
import TokenProvider from '../services/tokenProvider';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
var autosize = require('autosize')

class NewPost extends React.Component{
  constructor(props){
    super(props);
    this.state = { topics: [], text: '', topicsText: '', loading: false, selectedFiles: [], copied: false, author: "", baseUrl: ""}

    this.tokenProvider = TokenProvider.getTokenProvider();

    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleTopicsKeyDown = this.handleTopicsKeyDown.bind(this);
    this.handleRemoveTopic = this.handleRemoveTopic.bind(this);
    this.handleAddTopic = this.handleAddTopic.bind(this);

    this.textarea = React.createRef();
    this.topicsDropdown = React.createRef();
    this.topicsInput = React.createRef();
  }

  async componentDidMount(){
    autosize(this.textarea.current);
  }

  componentDidUpdate(){
    const left = Math.min(window.innerWidth*.5, this.topicsInput.current.offsetLeft)+"px";
    this.topicsDropdown.current.style.left = left;
  }

  handleTextChange(event){
    this.setState({ text: event.target.value.toString() });
  }

  handleTopicsKeyDown(event){
    const {topics} = this.state;

    //Backspace is pressed
    if(event.target.value.toString() === '' && event.which === 8 && topics.length > 0){
      topics.pop()
      this.setState({ topics })
    } 
  }

  handleRemoveTopic(topic){
    const {topics} = this.state;
    const index = topics.indexOf(topic);
    if (index !== -1)
      topics.splice(index, 1);
    this.setState({ topics });
  }

  handleAddTopic(topic){
    const {topics} = this.state;
    topics.push(topic);
    this.setState({ topics, topicsText: ''})
  }

  async handleSubmit(event){
    event.preventDefault();
    this.setState({loading: true});
    const {text, topics, author, baseUrl } = this.state;
    const formData = new FormData();
    formData.append('topics', JSON.stringify(topics));
    formData.append('body', text.trim());
    if(this.state.copied) {
      formData.append('user', author);
      formData.append('baseUrl', baseUrl);
    }
    const files = this.state.selectedFiles.map(({blob, objUrl})=>blob)
    files.forEach(image=>{
      formData.append('pictures', image);
    })
    const res = await this.tokenProvider.authFetch(`${this.props.apiUrl}/posts${this.state.copied ? "/copied" : ""}/create`, {
      method: 'POST',
      body: formData
    }).then(r=>r)
    if(res && res.ok)
      window.location.reload();
    else
      this.props.giveFeedback({message: 'Erreur inattendue 🤭', type:'error', enabled:true})
    this.setState({loading: false, topics: [], text: '', topicsText: '', selectedFiles: []});
  }

  render(){
    const { loading, text, topics,  topicsText} = this.state;

    return(
      <div class={`newPost ${loading ? 'disabled' : ''}`}>

        <div class="image">
          {
            this.tokenProvider.getImage() && this.tokenProvider.getImage() !== 'default' ? 
            <img alt='' src={`${serverUrl}/${this.tokenProvider.getImage().path}`}></img> : 
            <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                <path d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
            </svg>
          }
        </div>

        <form onSubmit={this.handleSubmit}>
          <section class='newPost__topics'>
            {
              topics.map(( topic, index) => (
                <div class='topics-selector__pill' key={index}>
                  {topic.name}
                  <svg onClick={()=>this.handleRemoveTopic(topic)} width="1.4em" height="1.4em" viewBox="0 0 16 16" class="bi bi-x pill__close" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </div>
              ))
            }
            <input
              ref ={this.topicsInput}
              value={topicsText}
              placeholder={`${topics.length === 0 ? 'Thèmes ...' : ''}`}
              class='topics-selector__input'
              readOnly
              type='text'></input>
            <div
              class={`topics-selector__dropdown`}
              ref ={this.topicsDropdown}>
              {
                this.props.allTopics.map((topic, index) => {
                  if(topic.name.toLowerCase().startsWith(topicsText.toLowerCase()) && !topics.includes(topic))
                    return (
                    <div key={index} onMouseDown={()=>this.handleAddTopic(topic)}>
                      {topic.name}
                    </div>
                  )
                  else return '';
                })
              }
            </div>
          </section>
          {
            ["admin", "editor"].includes(this.tokenProvider.getRole()) && this.state.copied &&
            <>
              <div class='newPost__divider'></div>
              <input
                className="newPost__user-input"
                type="text"
                placeholder="Rédacteur"
                value={this.state.author}
                onChange={(e)=>{this.setState({ author: e.target.value.toString() });}}
              ></input>
              <div class='newPost__divider'></div>
              <input
                className="newPost__user-input"
                type="text"
                placeholder="Url du post d'origine"
                value={this.state.baseUrl}
                onChange={(e)=>{this.setState({ baseUrl: e.target.value.toString() });}}
              ></input>
            </>
            
          }
          <div class='newPost__divider'></div>
          <textarea
            class='newPost__textarea'
            rows='1'
            value={text}
            onChange={this.handleTextChange}
            placeHolder={this.state.copied ? "Corps" : "Qu'avez-vous à dire ?"}
            ref={this.textarea}
          ></textarea>
          <div className='newPost__pictures'>
            {
              this.state.selectedFiles.map((image, index)=>
                <div className='newPost__selected-picture'>
                  <div class='newPost__rm-selected-picture' onClick={()=>{const t = this.state.selectedFiles; t.splice(index, 1); this.setState({selectedFiles: t})}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </div>
                  <img alt='' src={image.objUrl}></img>
                </div>
              )
            }
          </div>
          <div class="newPost__bottom">
            {
              ["admin", "editor"].includes(this.tokenProvider.getRole()) && 
              <div className="newPost__copied-toggler" >
                <ReactTooltip />
                <svg className="newPost__copied-icon" data-tip="Ce post est-il copié d'une autre plateforme ?"  width="28" height="28" viewBox="0 0 16 16">
                  <path d="M9.302 1.256a1.5 1.5 0 0 0-2.604 0l-1.704 2.98a.5.5 0 0 0 .869.497l1.703-2.981a.5.5 0 0 1 .868 0l2.54 4.444-1.256-.337a.5.5 0 1 0-.26.966l2.415.647a.5.5 0 0 0 .613-.353l.647-2.415a.5.5 0 1 0-.966-.259l-.333 1.242-2.532-4.431zM2.973 7.773l-1.255.337a.5.5 0 1 1-.26-.966l2.416-.647a.5.5 0 0 1 .612.353l.647 2.415a.5.5 0 0 1-.966.259l-.333-1.242-2.545 4.454a.5.5 0 0 0 .434.748H5a.5.5 0 0 1 0 1H1.723A1.5 1.5 0 0 1 .421 12.24l2.552-4.467zm10.89 1.463a.5.5 0 1 0-.868.496l1.716 3.004a.5.5 0 0 1-.434.748h-5.57l.647-.646a.5.5 0 1 0-.708-.707l-1.5 1.5a.498.498 0 0 0 0 .707l1.5 1.5a.5.5 0 1 0 .708-.707l-.647-.647h5.57a1.5 1.5 0 0 0 1.302-2.244l-1.716-3.004z"/>
                </svg>
                <Switch height={25} width={43} onChange={()=>this.setState({copied: !this.state.copied})} checked={this.state.copied}/>
              </div>
            }
            <label className='btn mr-1 my-0' htmlFor='picture'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-image" viewBox="0 0 16 16">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
              </svg>
            </label>
            <input
                className='newPost__picture-form'
                id='picture'
                type="file"
                accept=".jpeg,.jpg,.png,.gif"
                onChange={e=>this.setState({selectedFiles: [...this.state.selectedFiles,{blob: e.target.files[0], objUrl: URL.createObjectURL(e.target.files[0])}] })}
            ></input>
            <button
              class='btn btn-primary mr-3'
              type='submit'
              disabled={(text.toString().length === 0 && this.state.selectedFiles.length === 0) || topics.length === 0 || ( this.state.copied && this.state.author.length === 0)}
            >Publier</button>
          </div>
        </form>
      </div>
    )
  }
}

export default NewPost;