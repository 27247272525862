import React from 'react';

import './css/Confirm.css';

const Confirm = (props) => {
  return <>
    <div className='confirm'>
      <form>
          <div class='icon'>
              <img alt='sunflower' width='120' src={window.location.origin.toString()+'/icons/sunflower.svg'}/>
          </div>
          <h3 class='confirm__title'>Êtes-vous sûr de vouloir <strong>{props.message}</strong> ?</h3>
          <div class='confirm__buttons'>
            <div class='confirm__button-cancel' onClick={props.onCancel}>Annuler</div>
            <div class='confirm__button-confirm' onClick={props.onAccept}>Oui</div>
          </div> 
      </form>
    </div>
  </>
}

export default Confirm;