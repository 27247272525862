import React, { Component } from 'react';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom'

import TokenProvider from './services/tokenProvider';
import Loading from './components/Loading';
import Home from './routes/Home';
import Topics from './routes/Topics';
import SinglePost from './routes/SinglePost';
import Login from './routes/Login';
import SignUp from './routes/SignUp';
import Profile from './routes/Profile';
import Logout from './routes/Logout';
import FourOFour from './routes/FourOFour';
import Reset from './routes/Reset';
import Forgot from './routes/Forgot';

import './App.css';

export const serverUrl = "https://electroculturecommunity.com:4200";
export const apiUrl = `${serverUrl}/api`;

class App extends Component{

  constructor(){
    super();
    this.state={allTopics: [], likedPosts: [], isAuth: false, isLoading: false}
    this.tokenProvider = TokenProvider.getTokenProvider();
    this.listeners = this.listeners.bind(this);
    /*Prod :*/ this.apiUrl = apiUrl;
  }

  async getLikedPosts() {
    
    const res = await this.tokenProvider.authFetch(this.apiUrl+'/auth/likedPosts', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }).then(r=>r.json());
    if(res){
      this.setState({ likedPosts: [...res.likedPosts] });
    }
  }

  async listeners(newIsLogged){
    this.setState({isAuth: newIsLogged});
    if(newIsLogged)
      this.getLikedPosts();
    else
      this.setState({likedPosts: []});
  };

  async componentWillMount(){
    this.setState({isLoading: true});
    this.tokenProvider.subscribe(this.listeners);

    try{
      //Topics :
      const allTopics = await fetch(this.apiUrl+'/topics',{method: 'GET', headers: { Accept: "application/json", "Content-Type": "application/json"},}).then(res=>res.json());
      this.setState({allTopics: allTopics.topics});

      //Liked posts :
      if(this.tokenProvider.isLoggedIn())
        await this.getLikedPosts();
    }
    catch(error){
      this.allTopics = null;
    }
    this.setState({isLoading: false});
  }

  componentWillUnmount(){
    this.tokenProvider.unsubscribe(this.listeners);
  }

  render(){
    const {likedPosts} = this.state;
    if(this.state.allTopics === null || this.state.allTopics.length === 0 || this.state.isLoading)
      return <div class='app__loader'><Loading></Loading></div>
    
    else return (
      <div class='app'>
        <Router>

        <Switch>
          <Route exact path='/' render={()=><Home apiUrl={this.apiUrl} allTopics={this.state.allTopics} likedPosts={likedPosts} setLikedPosts={(posts)=>this.setState({likedPosts: [...posts]})}/>} ></Route>

          <Route path='/topics' render={()=><Topics apiUrl={this.apiUrl} allTopics={this.state.allTopics} likedPosts={likedPosts} setLikedPosts={(posts)=>this.setState({likedPosts: [...posts]})}/>}></Route>

          <Route exact path='/login' render={()=><Login apiUrl={this.apiUrl}/>}></Route>

          <Route exact path='/signup' render={()=><SignUp apiUrl={this.apiUrl}/>}></Route>

          <Route exact path='/logout' render={()=><Logout apiUrl={this.apiUrl}/>}/>

          <Route exact path='/forgot' render={()=><Forgot apiUrl={this.apiUrl}/>}></Route>

          <Route exact path='/reset/:token' render={()=><Reset apiUrl={this.apiUrl}/>}></Route>

          <Route exact path='/post/:id' render={()=><SinglePost apiUrl={this.apiUrl} allTopics={this.state.allTopics} likedPosts={likedPosts} setLikedPosts={(posts)=>this.setState({likedPosts: [...posts]})} />} />

          <Route exact path='/404' component={FourOFour}></Route>

          <Route exact path='/account/:login' render={()=><Profile apiUrl={this.apiUrl} allTopics={this.state.allTopics} likedPosts={likedPosts} />}/>

          <Route exact path='*'>
            <Redirect to='/404'/>
          </Route>

        </Switch>

        </Router>
      </div>
    )
  }
}

export default App;
