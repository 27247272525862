import React, {useRef, useEffect, useState} from "react";
import TokenProvider from "../services/tokenProvider";
import { serverUrl } from "../App";

import './css/NewReply.css';

var autosize = require('autosize')

const NewReply = (props) => {
  const tokenProvider = TokenProvider.getTokenProvider();
  const username = tokenProvider.getUsername();
  const textarea = useRef(null);
  const [text, setText] = useState('');

  useEffect(() => { autosize(textarea.current); }, [])

  const handleSubmitReply = async (e) => {
    const res = await tokenProvider.authFetch(props.apiUrl+'/posts/reply/'+props._id, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        body: text
      })
    }).then(r=>r)
    if(res && res.ok){
      const replys = props.responses;
      replys.push({user: {username: username, image: tokenProvider.getImage()}, body: text, date: Date.now() })
      props.setReplys(replys);
      setText('');
      props.giveFeedback({message: 'Réponse publiée', type:'success', enabled:true})
    }
    else
      props.giveFeedback({message: 'Erreur inattendue ', type:'error', enabled:true})  }

  return <>
  <div className='new-reply'>
    <div class='response mt-1 ml-4'>
      <div class='card m-0 p-1'>
        <div class='card-body d-flex p-2 align-items-center'>
          <div class='header d-flex align-items-center'>
            <div class='new-response__profile-pic'>
              {
                tokenProvider.getImage() && tokenProvider.getImage() !== 'default' ? 
                <img alt='' src={`${serverUrl}/${tokenProvider.getImage().path}`}></img> : 
                <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                    <path d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                </svg>
              }
            </div>
            <div class='name' to={'/'+username}>
              {username}
            </div>
          </div>
          <div class='body flex-grow-1 d-flex flex-nowrap flex-row justify-content-between mr-0'>
            <textarea
              class='newReply__content'
              rows='1'
              placeHolder={`Ajouter une réponse ...`}
              ref={textarea}
              value={text}
              onChange={e=>{setText(e.target.value.toString())}}
            ></textarea>
          </div>
          <button
            class='btn btn-primary'
            type='submit'
            disabled={text.toString().length === 0}
            onClick={handleSubmitReply}
            >Publier</button>
        </div>
      </div>
    </div>
  </div>
  </>
}

export default NewReply;