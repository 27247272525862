import React from 'react';
import './css/DisplayMessage.css';

const DisplayMessage = (props) => {
  return <div className={`display-message display-message--${props.type} ${!props.enabled?'disabled':''}`}>
    <svg className='display-message__x' onClick={props.onClose} width="1.7em" height="1.7em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>
    <div className='display-message__content'>
      {props.children}
    </div>
  </div>
}

export default DisplayMessage;