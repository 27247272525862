import React, {useState} from 'react';
import { 
				Link, 
				useRouteMatch,
				Switch,
				Route,
				Redirect
			} from 'react-router-dom'
import TokenProvider from '../services/tokenProvider'
import DisplayMessage from '../components/DisplayMessage'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import './css/Topics.css'

import NavBar from './NavBar'
import Topic from './Topic'
import Footer from '../components/Footer'
TimeAgo.addLocale(en);

const Topics = (props) => {
		const timeAgo = new TimeAgo('en-US');
		const { path, url} = useRouteMatch();
		const tokenProvider = TokenProvider.getTokenProvider();
		const [newTopic, setNewTopic] = useState('');
		const [message, setMessage] = useState({message: '', type:'', enabled:false});

		const translateTimeAgo = (text) => {
			var res = text.replace('a ', '1 ');
		
			if(text.includes('ago')){
				res = 'Il y a '+res.split(' ')[0]+' ';
				if(text.includes('second'))
					res+='seconde(s)'
				else if(text.includes('minute'))
					res+='minute(s)'
				else if(text.includes('hour'))
					res+='heure(s)'
				else if(text.includes('day'))
					res+='jour(s)'
				else if(text.includes('week'))
					res+='semaine(s)'
				else if(text.includes('month'))
					res+='moi(s)'
				else
					res+='an(s)'
			}
			else
				res = 'À l\'intant'
		
			return res.replace("an", "une");
		}

		const onNewTopicChange = (e) => {
			var val = e.target.value;
			setNewTopic(val);
			e.target.value = val;
		}

		const onSubmitNewTopic = async(e) => {
			e.preventDefault();
			const res = await tokenProvider.authFetch(props.apiUrl+'/topics/create', {
				method: 'POST',
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					topic: newTopic
				})
			}).then(r=>r);
			if(res && res.ok)
				window.location.reload();
			else
				setMessage({message: 'Erreur inattendue 🤭', type:'error', enabled:true});
		}

		return(<>
		 	{
				<DisplayMessage type={message.type} enabled={message.enabled} onClose={()=>setMessage({enabled: false})}>
						{message.message}
				</DisplayMessage>
			}
			<section>
			<NavBar/>
			<Switch>
				<Route exact path={path}>
					<div className='topics'>
						<div class='title'>
						<svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
						</svg>
							<h1 class='mx-2' >Parcourir</h1>
						</div>

						{tokenProvider.getRole()==='admin' && 
						<form className='new-topic' onSubmit={onSubmitNewTopic}>
								<input className='new-topic__name' type='text' name='name' placeholder='Nouveau thème' onChange={onNewTopicChange}></input>
								<button className={`btn btn-primary mx-2 ${newTopic.length === 0 && 'disabled'}`} type='submit' >Créer</button>
						</form>}

						<div class='links'>
							<ul class='list-group mx-5 shadow'>
								{
									props.allTopics.sort((a,b)=>Date.parse(b.lastUpdate) - Date.parse(a.lastUpdate)).map((topic, index) => {
										if(topic)
											return <Link to={`${url}/${topic.name.split(' ').join('-').toLowerCase()}`} class={`list-group-item list-group-item-action flex-column align-items-start`} key={index}>
												<div class="d-flex w-100 justify-content-between px-3">
													<h5 class="mb-1">{topic.name}</h5>
													<small >{topic.lastUpdate ? translateTimeAgo(timeAgo.format(Date.parse(topic.lastUpdate))) : 'Pas de publications'}</small>
												</div>
											</Link>
										else return ''
									})
								}
							</ul>
						</div>
					</div>
				</Route>

				{props.allTopics.map((topic, index) => (
					<Route exact path={`${path}/${topic.name.split(' ').join('-').toLowerCase()}`} key={index}>
						<Topic apiUrl={props.apiUrl} allTopics={props.allTopics} name={topic.name} likedPosts={props.likedPosts} setLikedPosts={props.setLikedPosts}></Topic>
					</Route>
				))}

				<Route exact path='*'>
					<Redirect to='/404'></Redirect>
				</Route>

			</Switch>
			</section>
			<Footer/>
		</>)
}

export default Topics