import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import './css/Login.css'

import Loading from '../components/Loading';
import TokenProvider from '../services/tokenProvider';

class Login extends React.Component{

    constructor(props){
        super(props)
        this.tokenProvider = TokenProvider.getTokenProvider();

        this.state = {
            emailUsed: false,
            loginError: '',
            isLoggedIn: this.tokenProvider.isLoggedIn(),
            isLoading: false,
            userOremail: '',
            password: '',
            userOrEmailValid: false,
            passwordValid: false
        }

        this.handleUserOrEmailChange = this.handleUserOrEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        const loginListener = (newIsLogged) => {
            this.setState({ isLoggedIn: newIsLogged });
        };
        this.tokenProvider.subscribe(loginListener);
    }

    componentWillUnmount(){
        this.tokenProvider.unsubscribe();
    }

    handleUserOrEmailChange(event){
        this.setState({ 
            userOrEmail: event.target.value.toString(),
            userOrEmailValid: event.target.value.toString().length > 0,
            emailUsed: event.target.value.includes('@')
        })
    }

    handlePasswordChange(event){
        this.setState({ 
            password: event.target.value.toString(),
            passwordValid: event.target.value.toString().length > 0
        })
    }

    async handleSubmit(event){
        event.preventDefault();
        const { userOrEmail, password, emailUsed } = this.state;

        //On met la page en chargement :
        this.setState({ isLoading: true });

        await fetch(this.props.apiUrl+'/auth/login', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: (emailUsed) ? JSON.stringify({
                email: userOrEmail,
                password
            }) : JSON.stringify({
                username: userOrEmail,
                password
            })
        }).then(res => {
            if(!res.ok){
                if(res.status === 400)
                    this.setState({ loginError: (emailUsed) ? 'Email ou mot de passe incorrect(e).' : 'Nom d\'utilisateur ou mot de passe incorrect.'})
                else
                    this.setState({ loginError: 'Une erreur est survenue.' })

                this.setState({
                    isLoading: false,
                    username: '',
                    email: '',
                    password: ''
                })
            }
            else
                return res.json();
        }).then(json => {
            this.setState({
                isLoading: false,
                username: '',
                email: '',
                password: ''
            });
            this.tokenProvider.setToken(json);
        })
        .catch( error => {
            console.error(error);
        })
    }

    render(){

        if(this.state.isLoggedIn){
            return <Redirect to='/' />;
        }

        const { isLoading, userOrEmail, password, userOrEmailValid, passwordValid, loginError} = this.state

        return(
            <div className='login'>
                {   isLoading ? <Loading /> :
                    <form onSubmit={this.handleSubmit}>
                        <div class='icon'>
                            <Link to='/'><img alt='sunflower' width='120' src='icons/sunflower.svg'/></Link>
                        </div>
                        <h3 class='mb-4'>Se connecter</h3>
                        <label for='email' class='mb-3'>Nom d'utilisateur ou email</label>
                        <input type='text' value={userOrEmail} onChange={this.handleUserOrEmailChange} class="mb-2 form-control" id="email"></input>

                        <label for='password' class='mb-3'>Mot de passe</label>
                        <Link to='/forgot' class='btn forgot-password'>Mot de passe oublié ?</Link>
                        <input type='password' value={password} onChange={this.handlePasswordChange} class="mb-3 form-control" id="password"></input>

                        <button type="submit" disabled={!(userOrEmailValid && passwordValid)} class={`${userOrEmailValid && passwordValid ? '' : 'disabled'} btn btn-success`}>Se connecter</button>
                        {
                        loginError ? <div class="text-center alert alert-danger alert-dismissible fade show mt-3" role="alert" >
                            {loginError}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> : ""
                        }
                        <Link to='/signup' class='btn orSignup'>Créer un compte</Link>
                    </form>
                }                    
            </div>
        )
    }
}

export default Login